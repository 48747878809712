<template>
  <div>
    <div class="member_lesson_infor_container">
      <div class="manager_lesson_infor_title">
        學生姓名：{{ studentName }}
      </div>
      <class-order-table
        :studentClassData="classOrderData"
        :studentDetail="daysClassDetail"
        @getClassOrder="getClassOrderList"
        @setClassDetailData="setClassDetail"
        @setDefaultClassOrder="setClassOrderArr"
        @setTeaOrder="setOrderFromChild"
        @showDialog="openDialog"
        @initPlanOnManager="createStdentPlan"
        @enterCreateTest="goCreateTest"
        @uploadFiles="submitUpload"
        @uploadOrdownLoad="upOrDownLoadStuWork"
        @editReadForm="editReadForm"
        :classId="3"
      ></class-order-table>
    </div>
    <!-- ... pup視窗-讀書規則 ... -->
    <div class="pup_study_plan" v-if="studentClassStatus == '1'">
      <div class="pup_manager_bg" @click="openDialog(0)"></div>
      <div class="pup_manager_window" style="top: 10vh;">
        <div style="padding-bottom: 80px">
          <div class="pup_manager_container">
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">完成日期</div>
              <div class="pup_manager_input prefix">
                <input v-model="stuReadForm.readingDate" :disabled="true" />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">讀書標題</div>
              <div class="pup_manager_input">
                <input v-model="stuReadForm.readingTitle" />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">讀書內容</div>
              <div class="pup_manager_input_textarea quillEditor">
                <!-- <textarea v-model="stuReadForm.readingContent"></textarea> -->
                <quill-editor ref="myQuillEditor" @onEditorsChange='changeReading'></quill-editor>
              </div> 
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">讀書鏈接</div>
              <div class="pup_manager_input">
                <input v-model="stuReadForm.readingUrl" />
              </div>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="setStuReading">
            確認規劃
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-讀書規則end ... -->

    <!-- ... pup視窗-安排上課 ... -->
    <div
      class="pup_setting_class"
      v-if="studentClassStatus == 2 || studentClassStatus == 4"
    >
      <div
        class="pup_manager_bg"
        style="z-index: 100"
        @click="openDialog(0)"
      ></div>
      <div class="pup_manager_window pup_height_window" style="z-index: 101">
        <div style="padding-bottom: 90px">
          <div class="pup_manager_container">
            <div v-if="isShowDate" class="pup_manager_input_container">
              <div class="pup_manager_input_title">
                {{ studentClassStatus == 4 ? "上課日期" : "測試日期" }}
              </div>
              <div class="pup_manager_input_time modal_date">
                <el-date-picker
                  style="width: 100%"
                  type="date"
                  value-format="yyyy-MM-dd"
                  @change="getFormList"
                  v-model="classOrderForm.scheduleDate"
                />
              </div>
            </div>
            <div v-if="studentClassStatus == 4">
              <div class="pup_manager_input_container">
                <div class="pup_manager_input_title">
                  上課時間
                  <span>*必選</span>
                </div>
                <div v-if="isShowDate" class="pup_manager_input_time">
                  <el-date-picker
                    type="date"
                    v-model="classOrderForm.scheduleDate"
                    placeholder="日期"
                  ></el-date-picker>
                </div>

                <div v-if="isShowDate" class="setting_time_colon">：</div>
                <div
                  :class="{
                    pup_manager_input_time: isShowDate,
                    pup_manager_input: !isShowDate,
                    prefix: !isShowDate,
                  }"
                >
                  <el-time-select
                    placeholder="時間"
                    @change="getFormList"
                    v-model="classOrderForm.startHour"
                    :picker-options="{
                      start: '14:00',
                      step: '01:00',
                      end: '21:00',
                    }"
                  ></el-time-select>
                </div>
              </div>
              <div class="pup_manager_input_container">
                <div class="pup_manager_input_title">
                  下課時間
                  <span>*必選</span>
                </div>
                <div v-if="isShowDate" class="pup_manager_input_time">
                  <el-date-picker
                    type="date"
                    v-model="classOrderForm.scheduleDate"
                    placeholder="日期"
                  ></el-date-picker>
                </div>
                <div v-if="isShowDate" class="setting_time_colon">：</div>
                <div
                  :class="{
                    pup_manager_input_time: isShowDate,
                    pup_manager_input: !isShowDate,
                    prefix: !isShowDate,
                  }"
                >
                  <el-time-select
                    placeholder="時間"
                    @change="getFormList"
                    v-model="classOrderForm.endHour"
                    :picker-options="{
                      start: '14:00',
                      step: '01:00',
                      end: '21:00',
                    }"
                  ></el-time-select>
                </div>
              </div>
              <div>
                <div class="pup_manager_input_container">
                  <div class="pup_manager_input_title">上課年級</div>
                  <div class="pup_manager_input">
                    <select
                      v-model="classOrderForm.grade"
                      @change="getFormList('culum')"
                    >
                      <option
                        v-for="(g, k) in hsdGradeList"
                        :key="k"
                        :value="g"
                      >
                        {{ g }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="pup_manager_input_container">
                  <div class="pup_manager_input_title">上課科目</div>
                  <div class="pup_manager_input">
                    <select
                      @change="getFormList"
                      v-model="classOrderForm.curriculum"
                    >
                      <option
                        v-for="(c, k) in culumList"
                        :key="k"
                        :value="c.curriculum"
                      >
                        {{ c.curriculum }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="pup_manager_input_container">
                  <div class="pup_manager_input_title">上課老師</div>
                  <div class="pup_manager_input">
                    <select v-model="classTeacherId">
                      <option
                        v-for="(t, k) in teacherList"
                        :key="k"
                        :value="t.teacherId"
                      >
                        {{ t.teacherName }}
                      </option>
                    </select>
                  </div>
                  <div v-if="teaListTip">當前時間沒有可選的上课老師</div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openDialog(0)"
            class="pup_manager_close"
            alt
          />
          <div class="pup_manager_window_btn" @click="createClassOrder">
            {{ studentClassStatus == 4 ? "確認開課" : "確認組卷" }}
          </div>
        </div>
      </div>
    </div>
    <!-- ... pup視窗-安排上課end ... -->

    <!-- ... pup視窗-上傳課後作業 ... -->
    <div class="pup_study_plan" v-if="studentClassStatus == 5">
      <div class="pup_manager_bg" @click="openDialog(0)"></div>
      <div class="pup_manager_window">
        <div>
          <div class="pup_manager_container">
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">作業標題</div>
              <div class="pup_manager_input">
                <input v-model="stuReadForm.homeworkTitle" />
              </div>
            </div>
            <div class="pup_manager_input_container">
              <div class="pup_manager_input_title">上傳作業</div>
              <div style="margin: 10px 0;width: 100%">
                <el-upload
                  ref="upload"
                  class="upload-demo"
                  action=""
                  :limit="10"
                  multiple
                  :with-credentials="true"
                  :http-request="httpRequest"
                  :on-change="handleChangeFile"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :on-exceed="handleExceed"
                  :file-list="fileList"
                  :auto-upload="false"
                >
                  <!-- multiple -->
                  <el-button size="middle" type="warning"> 點擊上傳 </el-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../../assets/image/close_icon.png"
          @click="openDialog(0)"
          class="pup_manager_close"
          alt
        />
        <div class="pup_manager_window_btn" @click="submitUpload">確認上傳</div>
      </div>
    </div>
    <!-- ... pup視窗-上傳課後作業end ... -->
    <!-- ... pup視窗-下载作业列表 ... -->
    <div class="pup_study_plan" v-if="studentClassStatus == 6 || studentClassStatus == 7">
      <div class="pup_manager_bg" @click="openDialog(0)"></div>
      <div class="pup_manager_window" style="top: 10vh;">
        <div>
          <div class="pup_manager_container">
            <a class="subjectUrl" v-for="work in homeworkList" :key="work.id" @click="downloadWorks(work,studentClassStatus == 6 ? 'subject' : 'answer')">
              <span class="subjectName">{{studentClassStatus == 6 ? work.SubjectFileName: work.AnswerFileName}}</span>
              <span><i class="el-icon-download" /></span>
            </a>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="openDialog(0)"
            class="pup_manager_close"
            alt
          />
        </div>
      </div>
    </div>
    <!-- ... pup視窗-下载作业列表end ... -->
  </div>
</template>
<script>
import classOrderTable from "../childComponent/class_order_table";
import combination from "../question/combination";
import quillEditor from "../childComponent/quill_editor"
import { mapState } from "vuex";
import {
  getAddReading,
  getHsdCulum,
  getTeaScheduleList,
  getTestList,
  getClassPlanOnManager,
  getAddClassOnManager,
  getAddClassOrderFree,
  getAddReadOnManager,
  getAddOrderOnManager,
  getCreateTestAfterOnManager,
  getTeacherFilter,
  getUpdateOnManager,
  getLearnPlanAnswer,
} from "../../api/api";
import { Message } from "element-ui";
import { hsdApi } from "@/api/baseApi";
// import QuillEditor from '../childComponent/quillEditor.vue';
export default {
  data() {
    return {
      stuReadForm: {},
      userId: "",
      checkId: 0, //开课类型
      classOrderData: [],
      studentClassStatus: 0, // 1 課前預習 2 一對一輔導 3 課後作業
      daysClassDetail: [],
      classOrderForm: {},
      teacherList: [],
      isShowDate: false, //1 家教 2 课程
      classDetailList: {},
      learnPlanId: "",
      scheduleDate: "",
      classTeacherId: "",
      culumList: [],
      teaListTip: false,
      fileList: [],
      studentName: "",
      homeworkList: [],
    };
  },
  components: {
    classOrderTable,
    combination,
    quillEditor,
  },
  computed: {
    ...mapState(["hsdGradeList"]),
  },
  created() {
    let name = this.$route.query.name ? decodeURI(this.$route.query.name) : "";
    this.studentName = name;
    this.userId = this.$route.query.id;
    let date = new Date();
    let y = date.getFullYear(),
        intM = date.getMonth(),
        d = date.getDate(),
        m = intM + 1;
    this.getClassOrderList(y, m, d);
  },
  methods: {
    //下载作业
    downloadWorks(work, type) {
      let href =
        type == "subject"
          ? "/api/v1/learn_plan/get_subject"
          : "/api/v1/learn_plan/get_answer";
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.style.display = "none";
      link.href = `${hsdApi}${href}?id=${work.Id}`;
      link.click();
      // event.preventDefault();
      return false;
    },
    async submitUpload() {
      let fileList = this.fileList;
      if (fileList && fileList.length) {
        let formData = new FormData();
        for(let i in fileList){
          formData.append("fileData", fileList[i].raw);
        }
        formData.append("learnPlanId", this.learnPlanId);
        formData.append("homeworkTitle", this.stuReadForm.homeworkTitle);
        const res = await getUpdateOnManager(formData);
        this.fileList = [];
        if (res && res.success == 1) {
          Message({
            type: "success",
            message: "上傳成功",
          });
          this.studentClassStatus = false;
          // this.fileList = []
        }
      }
    },
    httpRequest(param) {
      console.log(param);
      let fileObj = param.file; // 相当于input里取得的files
      // this.$emit("uploadFiles", fileObj);
      // axios.post(url, fd, config).then((res) => {
      //   if (res.code === 0) {
      //     this.submitForm(); //提交表单
      //   }
      // });
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.fileList = fileList;
    },
    handlePreview(file) {
      // this.fileList.push(file);
      console.log(file);
    },
    handleChangeFile(file) {
      this.fileList.push(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `當前限制選擇1個文件，本次選擇了 ${files.length} 個文件，共選擇了 ${
          files.length + fileList.length
        } 個文件`
      );
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    //進入組卷頁
    goCreateTest(type) {
      let routeData = this.$router.resolve({
        path: "/question",
        query: { type: type, learnPlanId: this.learnPlanId },
      });
      window.open(routeData.href, "_blank");
    },
    //下載學生作業
    async upOrDownLoadStuWork(down, learnPlanId, type) {
      if (down == "down") {
      } else if (down == "upload") {
        this.openDialog(5);
      } else {

      }
    },
    //获取行事历数据
    async getClassOrderList(y, m, d) {
      let logMonth = m < 10 ? `${y}-0${m}` : `${y}-${m}`;
      const res = await getClassPlanOnManager({
        logMonth,
        userId: this.userId,
      });
      if (res && res.code == 200 && res.success == 1) {
        this.setClassOrderArr(res.data.Data, d);
      }
    },

    setClassDetail(d) {
      this.daysClassDetail = this.classOrderData[d]
        ? this.classOrderData[d]
        : [];
      // console.log(this.classOrderData, this.daysClassDetail, d);
    },
    setOrderFromChild(item) {
      this.classDetailList = item;
    },
    setClassOrderArr(arr, d) {
      this.classOrderData = [];
      for (let i in arr) {
        this.classOrderData.push(arr[i].learnPlanList);
      }
      this.setClassDetail(d);
    },
    //学生免费排课
    async createClassOrderFree() {
      let params = Object.assign({}, this.classOrderForm, {
        userId: this.userId,
      });
      const res = await this.getAddClassOrderFree(params);
      if (res && res.success == 1) {
        Message({
          type: "success",
          message: "開課成功",
        });
        this.studentClassStatus = false;
      }
    },
    //編輯富文本
    changeReading(content){
      this.stuReadForm.readingContent = content
    },
    //行事曆編輯課前預習
    editReadForm(editForm){
      this.studentClassStatus = '1'
      this.stuReadForm = editForm.readForm;
      this.learnPlanId = editForm.learnPlanId
      this.$nextTick(() => {
        this.$refs['myQuillEditor'].content = this.stuReadForm.readingContent
      });
      
    },
    //发送课程预习安排
    async setStuReading() {
      const { readingDate } = this.stuReadForm
      const res = await getAddReadOnManager({
        ...this.stuReadForm,
        learnPlanId: this.learnPlanId,
      });
      if (res && res.code == 200 && res.success == 1) {
        this.studentClassStatus = 0;
        this.stuReadForm={ readingDate }
        Message({
          type: "success",
          message: "添加成功",
        });
      }
      // this.$router.push(`/question?type=test`);
    },
    //關閉彈窗
    openDialog(id, date) {
      this.studentClassStatus = id;
      if (id == 1) {
        let y = date[0],
          m = date[1],
          d = date[2];
        m = m > 9 ? m : `0${m}`;
        this.stuReadForm.readingDate = `${y}-${m}-${d}`;
        // console.log(date);
      }
      if (id == 4) {
        this.isShowDate = false;
      }
      if(id == 6 || id == 7){
        // console.log(date)
        this.homeworkList = date
      }
      if(id == 0){
        this.fileList = []
      }
    },
    //学生开课
    async createClassOrder() {
      let res = {},
        tData = {};
      if (this.classTeacherId) {
        tData.teacherId = this.classTeacherId;
      } else {
        return false;
      }
      for (let lk in this.classOrderForm) {
        if (lk.indexOf("Hour") >= 0) {
          tData[lk] = parseInt(this.classOrderForm[lk]);
        } else {
          tData[lk] = this.classOrderForm[lk];
        }
      }
      res = await getAddOrderOnManager({
        ...tData,
        learnPlanId: this.learnPlanId,
        userId: this.userId,
        scheduleDate: this.scheduleDate,
      });
      if (res && res.success == 1) {
        Message({
          type: "success",
          message: "開課成功",
        });
        this.studentClassStatus = false;
      }
    },
    //初始化课程规划
    async createStdentPlan(date) {
      console.log(date);
      //return;
      const [y, m, d] = date;
      let mon = m < 10 ? `0${m}` : m,
          day = d < 10 ? `0${d}` : d;
      this.scheduleDate = `${y}-${mon}-${day}`; //调接口专用格式
      const res = await getAddClassOnManager({
        studentId: this.userId,
        schedule_date: this.scheduleDate,
      });
      if (res && res.success == 1) {
        this.learnPlanId = res.data.id;
      }
    },
    //通过年级选择科目
    async selectCurrCulum() {
      const res = await getHsdCulum({grade: this.classOrderForm.grade});
      if (res && res.code == 200) {
        this.culumList = res.data;
      }
    },
    //获取老师列表
    getFormList(label) {
      if (label == "culum") {
        this.culumList = []
        this.selectCurrCulum();
      }
      let tData = {},
        isNoInput = false;
      for (let lk in this.classOrderForm) {
        if (this.classOrderForm[lk]) {
          if (lk.indexOf("Hour") >= 0) {
            tData[lk] = parseInt(this.classOrderForm[lk]);
          } else {
            tData[lk] = this.classOrderForm[lk];
          }
          isNoInput = true;
        } else {
          isNoInput = false;
          return;
        }
      }
      if (isNoInput) {
        this.getTeacherList(tData);
      }
    },
    async getTeacherList(data) {
      const res = await getTeacherFilter({
        ...data,
        scheduleDate: this.scheduleDate,
      });
      if (res && res.success == 1) {
        if (res.data) {
          this.teaListTip = false;
          this.teacherList = res.data;
        } else {
          this.teacherList = [];
          this.teaListTip = true;
        }
      }
    },
  },
};
</script>
<style>
.prefix .el-input__prefix {
  top: -10px;
}
.quillEditor{
  height: 150px;
  overflow-y: scroll;
}
.subjectName{
    display: inline-block;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.subjectUrl{
  cursor: pointer;
  color: #018247;
}
.subjectUrl:hover,.subjectUrl:active{
  color: #ffd737 ;
}
.el-upload-list{
  width: 420px;
}
</style>