<template>
  <div class="edit_container">
    <quill-editor
      v-model="content"
      ref="myQuillEditor"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)"
    >
    </quill-editor>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      content: "",
    //   editorOption: {},
      editorOption: {
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              "strike",
              "link",
              "image",
              "code-block",
            ], // toggled buttons
            [{ align: [] }], //对齐方式
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ font: [] }], //字体
            [{ size: ["small", "normal", "large", "huge"] }],
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
          ],
        },
      },
    };
  },
  methods: {
    onEditorReady(editor) {
      // 准备编辑器
    },
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange(e) {
        let val = e.html
        // console.log(val)
        this.$emit('onEditorsChange', val)
    }, // 内容改变事件
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
};
</script>
<style>
.edit_container .ql-toolbar.ql-snow{
    border: none ;
    padding: 0;
}
.edit_container .ql-container.ql-snow{
    border: none;
}
</style>
